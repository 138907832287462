import React from 'react'

import Gallery from '../../components/common/gallery';

const Earrings = () => (
  <Gallery
    imageLinks={
      [1, 2, 3, 4, 5, 6].map((number) => (
        `https://s3.amazonaws.com/valentina-site/ceramics_images/earrings/${number}image.jpg`
      ))
    }
    navigationColor='#edcba6'
    fromText='Ceramics'
    fromLink='/ceramics'
  />
)

export default Earrings